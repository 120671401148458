import {
	QueryClient,
	QueryFunction,
	QueryKey,
	QueryObserverResult,
} from '@tanstack/react-query'
import { WritableDraft } from 'immer/dist/types/types-external'
import moment from 'moment'
import { isDeepEqual } from 'remeda'
import {
	catchError,
	combineLatest,
	combineLatestWith,
	distinctUntilChanged,
	EMPTY,
	of,
	filter as rxjsFilter,
	map as rxjsMap,
	shareReplay,
	switchMap,
	tap,
} from 'rxjs'
import { StoreApi } from 'zustand'

import {
	Filter,
	mapTasksToAncestorFilters,
	mapTasksToOwnerFilters,
	mapTasksToPriorityFilters,
	mapTasksToTagsFilters,
	mapTasksToWorkflowFilters,
} from '../../filters'
import { TraceableError } from '../../helpers/error-handling'
import { createEmptySectionIndex } from '../../helpers/sections'
import { sortTasks, sortWithOrder } from '../../helpers/sort'
import { isInactive } from '../../helpers/taskStatus'
import { isString } from '../../type-guards'
import {
	PlayerOptions,
	SectionId,
	SectionSortType,
	Slice,
	SliceFilterActions,
	Task,
	TasksTableSort,
} from '../../types'
import { ApiAdapter, ApiListResult } from '../api'
import { updatePlayerOptionsMutation } from '../mutations/player'
import { updateSectionMutation } from '../mutations/sections'
import {
	addTaskSubject,
	createUserViewUserIdObservable,
	removeTaskSubject,
	updateTaskSubject,
} from '../observables'
import {
	createAssignedTasksQuery,
	createCompletedUserTasksQuery,
	createFollowUpTasksQuery,
	createQueryObservable,
	createSectionsQuery,
	playerKeys,
	taskKeys,
} from '../queries'
import { AppState, MutatedAppState, MutatedSetState } from '../store-types'
import useStore from '../useStore'
import { getServiceFromState, rejectFutureTasks } from '../utils'
import { buildSubtasksByParentId } from '../utils/buildSubtasksByParentId'
import { createZustandObservable } from '../utils/createZustandObservable'
import { mapExistingTasks } from '../utils/mapExistingTasks'
import { updateTaskListsInQueryCache } from '../utils/update-task-lists-in-query-cache'
import {
	createAddTaskToSections,
	createRemoveTaskFromSections,
	createSectionUpdateSocket,
	createUpdateTaskInFollowUp,
	createUpdateTaskInSections,
	rebuildSections,
	SectionsById,
} from '../utils/userViewSectionsState'
import {
	createAddTaskToTable,
	createRemoveTaskFromTable,
	createUpdateTaskInTable,
	rebuildTable,
} from '../utils/userViewTableState'
import { changeUser } from './user-view-state-data'
import {
	addFilterMutation,
	removeFilterMutation,
	resetFiltersMutation,
} from './user-view-state-filters'
import { tableSortDefaults } from './user-view-utils'

const isQueryObserverResult = <T>(
	result: QueryObserverResult | undefined
): result is QueryObserverResult<T> => result !== undefined

const getAssignedTasksObservable = (
	apiAdapter: ApiAdapter,
	queryClient: QueryClient,
	userId: string
) =>
	createQueryObservable(
		queryClient,
		createAssignedTasksQuery(apiAdapter, queryClient)(userId)
	).pipe(rxjsFilter(isQueryObserverResult))
const getCompletedUserTasksObservable = (
	apiAdapter: ApiAdapter,
	queryClient: QueryClient,
	userId: string
) =>
	createQueryObservable(
		queryClient,
		createCompletedUserTasksQuery(apiAdapter, queryClient)(userId)
	).pipe(rxjsFilter(isQueryObserverResult))

const getTableSort = (queryClient: QueryClient): TasksTableSort => {
	const playerOptions = queryClient.getQueryData<PlayerOptions>(
		playerKeys.options()
	)
	return playerOptions && playerOptions['tasksTableSort']
		? (playerOptions['tasksTableSort'] as TasksTableSort)
		: tableSortDefaults
}

const rebuildFilters = (
	set: MutatedSetState,
	get: StoreApi<AppState>['getState'],
	taskIds: string[]
) => {
	if (!taskIds) {
		return
	}

	const mappedTasks = mapExistingTasks(
		get().queryClient,
		taskIds.filter((id) => id !== get().player.inboxTaskId)
	)

	const immutableFilters = (
		newFilters: Filter[],
		currentFilters: Filter[]
	) => {
		if (
			isDeepEqual(
				newFilters.map((filter) => [filter.id, filter.count]),
				currentFilters.map((filter) => [filter.id, filter.count])
			)
		) {
			return currentFilters
		} else {
			return newFilters
		}
	}

	set((draft) => {
		const currentUserId = draft.userView.user.id
		const currentUserView = draft.userView.userViews[currentUserId || '']

		if (!currentUserView) {
			return
		}

		currentUserView.filter.folder = immutableFilters(
			mapTasksToAncestorFilters(mappedTasks),
			currentUserView.filter.folder
		)
		currentUserView.filter.owner = immutableFilters(
			mapTasksToOwnerFilters(mappedTasks),
			currentUserView.filter.owner
		)
		currentUserView.filter.priority = immutableFilters(
			mapTasksToPriorityFilters(mappedTasks),
			currentUserView.filter.priority
		)
		currentUserView.filter.tags = immutableFilters(
			mapTasksToTagsFilters(mappedTasks),
			currentUserView.filter.tags
		)
		currentUserView.filter.workflow = immutableFilters(
			mapTasksToWorkflowFilters(mappedTasks),
			currentUserView.filter.workflow
		)
	})
}

const sortSection = (draft: WritableDraft<AppState>, sectionId: SectionId) => {
	const section =
		draft.userView.userViews[draft.userView.user.id].sections.byId[
			sectionId
		]
	if (!section) {
		return
	}

	const queryClient = draft.queryClient as QueryClient
	const tasks = mapExistingTasks(queryClient, section.tasks)

	const sortedTasks =
		section.sortType === 'manual'
			? sortWithOrder(tasks, section.manualSortOrder)
			: sortTasks(tasks, section.sortType)
	section.tasks = sortedTasks.map((t) => t.id)
}

type RefetchType = 'sections' | 'tasks'

export interface UserViewData {
	activeTasks: {
		isLoading: boolean
		privateTaskCount: number
		taskIds: string[]
	}
	completedTasks: {
		isLoading: boolean
		privateTaskCount: number
		taskIds: string[]
	}
	filter: {
		folder: Filter[]
		owner: Filter[]
		priority: Filter[]
		search: Filter[]
		selected: Filter[]
		tags: Filter[]
		workflow: Filter[]
	}
	followUp: {
		isLoading: boolean
		taskIds: string[]
	}
	sections: {
		byId: SectionsById
		displayOrder: SectionId[]
		isFetching: boolean
		isLoading: boolean
		privateTaskCount: number
		taskCount: number
	}
	subtasksByParentId: {
		[parentId: string]: string[]
	}
	table: {
		sort: TasksTableSort
		taskIds: string[]
	}
}

export interface UserViewSlice extends Slice, SliceFilterActions {
	userViews: Record<string, UserViewData> | Record<string, null>
	selectedDate: Date
	isSelectedDateLoading: boolean
	setSelectedDateLoading: (value: boolean) => void
	setSelectedDate: (date: Date) => void
	user: {
		id: string | null
	}
	refetch: (type: RefetchType) => void
	setSectionSortType: (
		sectionId: SectionId,
		sortType: SectionSortType,
		manualSortOrder?: string[]
	) => void
	setTableSort: (sort: TasksTableSort) => void
	setUser: (userId: string) => void
}

export const createUserViewSlice: MutatedAppState<UserViewSlice> = (
	set,
	get,
	api
) => ({
	user: {
		id: null,
	},
	userViews: {},
	selectedDate: moment().utc().toDate(),
	isSelectedDateLoading: false,
	init: () => {
		const apiAdapter$ = getServiceFromState(api, 'apiAdapter')
		const queryClient$ = getServiceFromState(api, 'queryClient')
		const socket$ = getServiceFromState(api, 'socket')

		const userViewFilter$ = createZustandObservable(
			api,
			(state) =>
				state.userView.userViews[state.userView.user.id || '']?.filter
		)

		const userId$ = createUserViewUserIdObservable(api).pipe(
			rxjsMap(({ state }) => state),
			rxjsFilter(isString),
			shareReplay(1)
		)

		const activeUserTasks$ = combineLatest([
			apiAdapter$,
			queryClient$,
			userId$,
		]).pipe(
			switchMap(([apiAdapter, queryClient, userId]) =>
				getAssignedTasksObservable(apiAdapter, queryClient, userId)
			),
			shareReplay(1)
		)

		const completedUserTasks$ = combineLatest([
			apiAdapter$,
			queryClient$,
			userId$,
		]).pipe(
			switchMap(([apiAdapter, queryClient, userId]) =>
				getCompletedUserTasksObservable(apiAdapter, queryClient, userId)
			),
			shareReplay(1)
		)

		const sectionData$ = combineLatest([
			apiAdapter$,
			queryClient$,
			userId$,
		]).pipe(
			switchMap(([apiAdapter, queryClient, userId]) => {
				if (userId) {
					return createQueryObservable(
						queryClient,
						createSectionsQuery(apiAdapter, queryClient)(userId)
					)
				} else {
					// set(resetUserView)
					return EMPTY
				}
			}),
			rxjsFilter((results) => Boolean(results.data)),
			distinctUntilChanged(isDeepEqual)
		)

		// Control flow:
		// Initialise defaults from player options
		// Listen to local user action events to update sections and/or table
		//  - add task
		//  - remove task
		//  - update task
		// Listen to socket events to update sections and/or table
		//  - section update
		//  - add task
		//  - remove task
		//  - update task
		// Listen to store filters changes to update sections and/or table
		// Listen to store userId changes to update sections and/or table
		//  - listen to query cache changes on section data
		//  - listen to query cache changes on tasks (active/completed)
		//  - listen to query change changes on follow up tasks
		//  - set isLoading state after all relevant data has loaded

		// TODO: separate initialisation from subscription
		/* const tasksSubscription = createUserViewTasksObservable({
			queryClient,
			socket,
		}).subscribe((tasks: Task[]) => {
			const resultMap = indexBy(prop('id'), tasks)
			set(state => {
				state.userView.tasks.byId = resultMap
				state.userView.tasks.sort = map(prop('id'), tasks)
			})
		}) */

		const activeData$ = activeUserTasks$.pipe(
			tap((result) => {
				set((draft) => {
					const currentUserId = draft.userView.user.id
					const currentUserView =
						draft.userView.userViews[currentUserId || '']
					if (currentUserView) {
						currentUserView.activeTasks.isLoading = result.isLoading
					}
				})
			}),
			rxjsMap((result) => result.data),
			rxjsFilter((data) => data !== undefined),
			distinctUntilChanged(isDeepEqual),
			tap((data) => {
				set((draft) => {
					const currentUserId = draft.userView.user.id
					const currentUserView =
						draft.userView.userViews[currentUserId || '']
					if (!currentUserView) return

					const taskIds = data.items.filter(
						(id: string) => id !== draft.player.inboxTaskId
					)
					const activeTasks = mapExistingTasks(
						get().queryClient,
						taskIds
					)
					currentUserView.activeTasks.privateTaskCount =
						data.privateTaskCount
					currentUserView.activeTasks.taskIds = taskIds

					const nonFutureTasks = rejectFutureTasks(
						activeTasks
					) as Task[]
					currentUserView.subtasksByParentId =
						buildSubtasksByParentId(nonFutureTasks)
				})
			})
		)

		const completedData$ = completedUserTasks$.pipe(
			tap((result) => {
				set((draft) => {
					const currentUserId = draft.userView.user.id
					const currentUserView =
						draft.userView.userViews[currentUserId || '']
					if (currentUserView) {
						currentUserView.completedTasks.isLoading =
							result.isLoading
					}
				})
			}),
			rxjsMap((result) => result.data),
			rxjsFilter((data) => data !== undefined),
			distinctUntilChanged(isDeepEqual),
			tap((data) => {
				set((draft) => {
					const currentUserId = draft.userView.user.id
					const currentUserView =
						draft.userView.userViews[currentUserId || '']
					if (!currentUserView) return

					currentUserView.completedTasks.privateTaskCount =
						data.privateTaskCount
					currentUserView.completedTasks.taskIds = data.items
				})
			})
		)

		const combinedTasks$ = combineLatest([
			activeData$.pipe(
				rxjsMap((data) => data.items),
				distinctUntilChanged(isDeepEqual)
			),
			completedData$.pipe(
				rxjsMap((data) => data.items),
				distinctUntilChanged(isDeepEqual)
			),
		]).pipe(
			tap(([activeTasks, completedTasks]) => {
				setTimeout(() => {
					rebuildFilters(set, get, [
						...activeTasks,
						...completedTasks,
					])
					rebuildTable(set)
				})
			})
		)

		// Initialise state that's dependant on player options
		// TEMPORARY: the delay allows some time for the options query to be
		// cached.
		// TODO: store player options in player state and this can subscribe to
		// that
		setTimeout(() => {
			set((draft) => {
				const currentUserId = draft.userView.user.id
				const currentUserView =
					draft.userView.userViews[currentUserId || '']
				if (currentUserView) {
					currentUserView.table.sort = getTableSort(get().queryClient)
				}
			})
		}, 1000)

		const updateTaskInFollowUp = createUpdateTaskInFollowUp(set)

		const addTaskToSections = createAddTaskToSections(set, get)
		const removeTaskFromSections = createRemoveTaskFromSections(set, get)
		const updateTaskInSections = createUpdateTaskInSections(set)

		const addTaskToTable = createAddTaskToTable(set, get)
		const removeTaskFromTable = createRemoveTaskFromTable(set, get)
		const updateTaskInTable = createUpdateTaskInTable(set, get)

		// Add task to sections and table
		const addTaskSubscription = addTaskSubject
			.pipe(rxjsMap(({ task }) => task))
			.subscribe((task) => {
				// TODO: rollback failed addTask actions
				console.log('called add to task')
				addTaskToSections(task)
				addTaskToTable(task)

				const currentUserId = get().userView.user.id
				const currentUserView =
					get().userView.userViews[currentUserId || '']
				if (currentUserView && task.assigneeId === currentUserId) {
					// TODO: simplify this
					const tasks = get().queryClient.getQueryData<
						ApiListResult<string>
					>(taskKeys.list({ assigneeId: currentUserId }))
					if (tasks && tasks.items) {
						if (tasks.items.includes(task.id)) {
							return
						}

						const newTasks = {
							...tasks,
							items: [...tasks.items, task.id],
						}
						get().queryClient.setQueryData(
							taskKeys.list({ assigneeId: currentUserId }),
							newTasks
						)
					}
				}

				updateTaskListsInQueryCache(get().queryClient, task.id)
			})

		const removeTaskSubscription = removeTaskSubject.subscribe((taskId) => {
			// TODO: rollback failed removeTask actions
			removeTaskFromSections(taskId)
			removeTaskFromTable(taskId)
			updateTaskListsInQueryCache(get().queryClient, taskId)
		})

		const updateTaskSubscription = updateTaskSubject.subscribe(
			({ taskId, changes }) => {
				const currentUserId = get().userView.user.id
				const currentUserView =
					get().userView.userViews[currentUserId || '']

				if (!currentUserView) return

				if ('assigneeId' in changes) {
					// If assigneeId changes from the currently viewed user,
					// then remove the task from the active tasks list.
					if (changes.assigneeId !== currentUserId) {
						removeTaskFromSections(taskId)
						removeTaskFromTable(taskId)

						// TODO: move to table state?
						const result = get().queryClient.getQueryData<
							ApiListResult<string>
						>(taskKeys.list({ assigneeId: currentUserId }))
						if (result && result.items) {
							const newTasks = {
								...result,
								items: result.items.filter(
									(id) => id !== taskId
								),
							}
							get().queryClient.setQueryData(
								taskKeys.list({ assigneeId: currentUserId }),
								newTasks
							)
						}

						return
					}
				}

				if (
					('statusCode' in changes &&
						isInactive(changes.statusCode)) ||
					('isActive' in changes && !changes.isActive)
				) {
					removeTaskFromSections(taskId)
					removeTaskFromTable(taskId)

					const result = get().queryClient.getQueryData<
						ApiListResult<string>
					>(taskKeys.list({ assigneeId: currentUserId }))
					if (result && result.items) {
						const newTasks = {
							...result,
							items: result.items.filter((id) => id !== taskId),
						}
						get().queryClient.setQueryData(
							taskKeys.list({ assigneeId: currentUserId }),
							newTasks
						)
					}
				}

				if ('startDate' in changes) {
					set((draft) => {
						const currentUserId = draft.userView.user.id
						const currentUserView =
							draft.userView.userViews[currentUserId || '']
						if (!currentUserView) return

						const activeTasks = mapExistingTasks(
							get().queryClient,
							currentUserView.activeTasks.taskIds
						)
						const nonFutureTasks = rejectFutureTasks(
							activeTasks
						) as Task[]
						currentUserView.subtasksByParentId =
							buildSubtasksByParentId(nonFutureTasks)
					})
				}

				// TODO: rollback failed updateTask actions
				updateTaskInFollowUp(taskId, changes)
				updateTaskInSections(taskId, changes)
				updateTaskInTable(taskId, changes)
				updateTaskListsInQueryCache(get().queryClient, taskId)
			}
		)

		const sectionUpdateSocketSubscription = socket$
			.pipe(switchMap((socket) => createSectionUpdateSocket(socket)))
			.subscribe((data) => {
				set((draft) => {
					const currentUserId = draft.userView.user.id
					const currentUserView =
						draft.userView.userViews[currentUserId || '']
					if (!currentUserView) {
						return
					}

					// Only change data on the currently viewed user tasks page
					if (data.userId !== currentUserId) {
						return
					}

					const section =
						currentUserView.sections.byId[data.section.id]
					if (!section) {
						return
					}

					section.manualSortOrder = data.section.manualSortOrder
					section.sortType = data.section.sortType
					section.title = data.section.title

					sortSection(draft, data.section.id)
				})
			})

		// Store changes

		// Rebuild sections when filter changes
		const userViewFilterSubscription = userViewFilter$.subscribe(() => {
			// TODO: create a rebuildView function that adapts to current
			// view instead of building views that the user isn't seeing.
			rebuildSections(set)
			rebuildTable(set)
		})

		const followUpTasksSubscription = userId$
			.pipe(
				// Only load follow up tasks for the current user
				rxjsFilter((userId) => userId != null),
				rxjsFilter((userId) => userId === get().player.id),
				combineLatestWith(apiAdapter$, queryClient$),
				switchMap(([, apiAdapter, queryClient]) =>
					createQueryObservable(
						queryClient,
						createFollowUpTasksQuery(apiAdapter, queryClient)()
					)
				),
				tap((results) => {
					if (!results.data) {
						return
					}
					set((draft) => {
						const currentUserId = draft.userView.user.id
						const currentUserView =
							draft.userView.userViews[currentUserId || '']
						if (!currentUserView) return

						// TODO: fix this type
						// eslint-disable-next-line
						// @ts-ignore
						currentUserView.followUp.taskIds = results.data.items
						currentUserView.followUp.isLoading = false
					})
				}),
				catchError((err, caught) => {
					console.debug('followUpTasksSubscription', { caught })
					console.error('followUpTasksSubscription', err)
					return of(err)
				})
			)
			.subscribe()

		const sectionDataSubscription = combineLatest([
			sectionData$,
			combinedTasks$,
		])
			.pipe(
				distinctUntilChanged((previous, current) => {
					const [prevSectionData, prevCombinedTasks] = previous
					const [currSectionData, currCombinedTasks] = current

					return (
						isDeepEqual(
							prevSectionData.data,
							currSectionData.data
						) && isDeepEqual(prevCombinedTasks, currCombinedTasks)
					)
				}),
				tap(([sectionData]) => {
					set((draft) => {
						const currentUserId = draft.userView.user.id
						const currentUserView =
							draft.userView.userViews[currentUserId || '']
						if (!currentUserView) {
							draft.userView.userViews[
								currentUserId || ''
							].sections.isLoading = false
							return
						}

						currentUserView.sections.isLoading =
							sectionData.isLoading ||
							currentUserView.activeTasks.isLoading
					})
					rebuildSections(set)
				}),
				// TODO: handle errors
				catchError((err, caught) => {
					console.debug('sectionDataSubscription', { caught })
					console.error('sectionDataSubscription', err)
					return of(err)
				})
			)
			.subscribe()

		return () => {
			sectionUpdateSocketSubscription.unsubscribe()
			addTaskSubscription.unsubscribe()
			removeTaskSubscription.unsubscribe()
			updateTaskSubscription.unsubscribe()

			followUpTasksSubscription.unsubscribe()
			sectionDataSubscription.unsubscribe()

			userViewFilterSubscription.unsubscribe()
		}
	},
	refetch: async (type) => {
		const userId = get().userView.user.id
		// We can't refetch anything if a user isn't selected
		if (!userId) {
			return
		}

		const sectionsQueryParams = createSectionsQuery(
			get().apiAdapter,
			get().queryClient
		)(userId)
		const sectionsQuery = () =>
			get().queryClient.fetchQuery(
				sectionsQueryParams.queryKey as QueryKey,
				sectionsQueryParams.queryFn as QueryFunction,
				// Force a refetch even if data is not stale
				{ staleTime: 0 }
			)

		const activeTasksQueryParams = createAssignedTasksQuery(
			get().apiAdapter,
			get().queryClient
		)(userId)
		const activeTasksQuery = () =>
			get().queryClient.fetchQuery(
				activeTasksQueryParams.queryKey as QueryKey,
				activeTasksQueryParams.queryFn as QueryFunction,
				// Force a refetch even if data is not stale
				{ staleTime: 0 }
			)

		const completedTasksQueryParams = createCompletedUserTasksQuery(
			get().apiAdapter,
			get().queryClient
		)(userId)
		const completedTasksQuery = () =>
			get().queryClient.fetchQuery(
				completedTasksQueryParams.queryKey as QueryKey,
				completedTasksQueryParams.queryFn as QueryFunction,
				// Force a refetch even if data is not stale
				{ staleTime: 0 }
			)

		switch (type) {
			case 'sections':
				set((draft) => {
					const currentUserId = draft.userView.user.id
					const currentUserView =
						draft.userView.userViews[currentUserId || '']
					if (currentUserView) {
						currentUserView.sections.isFetching = true
					}
				})
				try {
					await sectionsQuery()
				} catch (err: unknown) {
					throw new TraceableError('Failed to refetch sections', err)
				}
				set((draft) => {
					const currentUserId = draft.userView.user.id
					const currentUserView =
						draft.userView.userViews[currentUserId || '']
					if (currentUserView) {
						currentUserView.sections.isFetching = false
					}
				})
				break

			case 'tasks':
				activeTasksQuery()
				completedTasksQuery()
				break

			// Default to fetching all queries
			default:
				set((draft) => {
					const currentUserId = draft.userView.user.id
					const currentUserView =
						draft.userView.userViews[currentUserId || '']
					if (currentUserView) {
						currentUserView.sections.isFetching = true
					}
				})
				try {
					await sectionsQuery()
				} catch (err: unknown) {
					throw new TraceableError('Failed to refetch sections', err)
				}
				set((draft) => {
					const currentUserId = draft.userView.user.id
					const currentUserView =
						draft.userView.userViews[currentUserId || '']
					if (currentUserView) {
						currentUserView.sections.isFetching = false
					}
				})
				activeTasksQuery()
				completedTasksQuery()
				break
		}
	},
	setSectionSortType: (
		sectionId,
		sortType = 'newest',
		manualSortOrder = []
	) => {
		const userId = get().userView.user.id
		if (!userId) {
			return
		}

		const { apiAdapter, queryClient } = useStore.getState()
		updateSectionMutation({ apiAdapter, queryClient })(userId, sectionId, {
			manualSortOrder,
			sortType,
		})
		set((draft) => {
			const currentUserView = draft.userView.userViews[userId]
			if (!currentUserView) {
				return
			}

			const section = currentUserView.sections.byId[sectionId]
			if (!section) {
				return
			}

			section.sortType = sortType
			if (manualSortOrder) {
				section.manualSortOrder = manualSortOrder
			}

			sortSection(draft, sectionId)
		})
	},
	setTableSort: (sort) => {
		set((draft) => {
			const currentUserId = draft.userView.user.id
			const currentUserView =
				draft.userView.userViews[currentUserId || '']
			if (currentUserView) {
				currentUserView.table.sort = sort
			}
		})
		rebuildTable(set)
		updatePlayerOptionsMutation(get(), { tasksTableSort: sort })
	},
	setUser: (userId) => {
		// Only change user if the user is different
		if (userId === get().userView.user.id) {
			return
		}
		set((draft) => {
			// Initialize userView for the new user if not exists
			if (!draft.userView.userViews[userId]) {
				draft.userView.userViews[userId] = {
					activeTasks: {
						isLoading: false,
						privateTaskCount: 0,
						taskIds: [],
					},
					completedTasks: {
						isLoading: false,
						privateTaskCount: 0,
						taskIds: [],
					},
					filter: {
						folder: [],
						owner: [],
						priority: [],
						search: [],
						selected: [],
						tags: [],
						workflow: [],
					},
					followUp: {
						isLoading: false,
						taskIds: [],
					},
					sections: {
						byId: createEmptySectionIndex(),
						displayOrder: [],
						isFetching: false,
						isLoading: false,
						privateTaskCount: 0,
						taskCount: 0,
					},
					subtasksByParentId: {},
					table: {
						sort: tableSortDefaults,
						taskIds: [],
					},
				}
			}

			changeUser(userId)(draft)
			// resetFiltersMutation(draft)
		})
	},

	// Filter actions
	addFilter: (filter) => {
		if (!filter) {
			return
		}
		set((draft) => {
			const currentUserId = draft.userView.user.id
			const currentUserView =
				draft.userView.userViews[currentUserId || '']
			if (currentUserView) {
				addFilterMutation(filter)(draft)
			}
		})
	},
	clearFilters: () => {
		set((draft) => {
			const currentUserId = draft.userView.user.id
			const currentUserView =
				draft.userView.userViews[currentUserId || '']
			if (currentUserView) {
				resetFiltersMutation(draft)
			}
		})
	},
	removeFilter: (filterType) => {
		if (!filterType) {
			return
		}
		set((draft) => {
			const currentUserId = draft.userView.user.id
			const currentUserView =
				draft.userView.userViews[currentUserId || '']
			if (currentUserView) {
				removeFilterMutation(filterType)(draft)
			}
		})
	},

	// Date actions
	setSelectedDate(date) {
		set((draft) => {
			draft.userView.selectedDate = date
		})
	},
	setSelectedDateLoading(value) {
		set((draft) => {
			draft.userView.isSelectedDateLoading = value
		})
	},
})

export default createUserViewSlice
