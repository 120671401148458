import { QueryClient } from '@tanstack/react-query'
import { filter, length, map } from 'ramda'
import { isDeepEqual, prop, sortBy } from 'remeda'
import { GetState } from 'zustand'

import { AndCriteria } from '../../filters'
import durationFormat from '../../helpers/formatters/duration-format'
import {
	sortFnsForDueDate,
	sortFnsForDuration,
	sortFnsForOwner,
	sortFnsForPriority,
	sortFnsForProject,
	sortFnsForStartDate,
	sortFnsForTitle,
	TaskSortOrderRule,
} from '../../helpers/sort'
import { isActive } from '../../helpers/taskStatus'
import mergeTask from '../../tasks/mergeTask'
import { Task } from '../../types'
import { taskKeys } from '../queries'
import { AppState, MutatedSetState } from '../store-types'
import { mapExistingTasks } from './mapExistingTasks'

export type Column = {
	id: string
	label: string
	sort: TaskSortOrderRule[]
}

const flipDirection = (
	sourceDirection: 'asc' | 'desc',
	targetDirection: 'asc' | 'desc'
) => {
	if (targetDirection === 'asc') {
		return sourceDirection
	}

	// Flip source direction if target direction is 'desc'
	if (sourceDirection === 'asc') {
		return 'desc'
	} else {
		return 'asc'
	}
}

const flipOrderRules = (
	orderRules: TaskSortOrderRule[],
	direction: 'asc' | 'desc'
): TaskSortOrderRule[] =>
	orderRules.map((rule) => {
		if (Array.isArray(rule)) {
			return [rule[0], flipDirection(rule[1], direction)]
		}
		return [rule, direction]
	})

export const userViewTableColumns: Column[] = [
	{ id: 'owner', label: 'Owner', sort: sortFnsForOwner },
	{ id: 'title', label: 'Title', sort: sortFnsForTitle },
	{ id: 'project', label: 'Project', sort: sortFnsForProject },
	{ id: 'duration', label: 'Duration', sort: sortFnsForDuration },
	{ id: 'startDate', label: 'Start', sort: sortFnsForStartDate },
	{ id: 'dueDate', label: 'Due', sort: sortFnsForDueDate },
	{
		id: 'priority',
		label: 'Priority',
		sort: flipOrderRules(sortFnsForPriority, 'desc'),
	},
	//{ id: 'followers', label: 'Followers', sort: ['followerCount', 'title'] },
]

const formatTasks = map((task: Task) => {
	const parents = task?.parents || []
	const breadcrumb = parents.map(prop('title')).join(' / ')
	const duration = durationFormat(Math.round(task.hoursAllocated * 3600))
	const followerCount = length(task.followers)

	return {
		...task,
		breadcrumb,
		duration,
		followerCount,
	}
})

const sortTasks = (
	tasks: Task[],
	direction: 'asc' | 'desc' = 'asc',
	columnId: string
): Task[] => {
	const column = userViewTableColumns.find((column) => column.id === columnId)
	const formattedTasks = formatTasks(tasks)
	return column
		? sortBy(tasks, ...flipOrderRules(column.sort, direction))
		: formattedTasks
}

export const createAddTaskToTable =
	(set: MutatedSetState, get: GetState<AppState>) => (task?: Task) => {
		set((draft) => {
			const playerId = draft.player.id
			const userId = draft.userView.user.id

			if (
				!userId ||
				!playerId ||
				!task ||
				// Check if task is assigned to current user
				userId !== task.assigneeId ||
				// Check if task is inactive
				!isActive(task.statusCode)
			) {
				return
			}

			// Only add task if it's not already in the list
			if (
				!draft.userView.userViews[userId]?.activeTasks.taskIds.includes(
					task.id
				)
			) {
				draft.userView.userViews[userId]?.activeTasks.taskIds.push(
					task.id
				)
			}
		})

		setTimeout(() => {
			rebuildTable(set)
		})
	}

export const createAddCompletedTask =
	(set: MutatedSetState, get: GetState<AppState>) => (task?: Task) => {
		set((draft) => {
			const playerId = draft.player.id
			const userId = draft.userView.user.id

			if (
				!userId ||
				!playerId ||
				!task ||
				// Check if task is assigned to current user
				userId !== task.assigneeId
			) {
				return
			}

			// Only add task if it's not already in the list
			if (
				!draft.userView.userViews[
					userId
				]?.completedTasks.taskIds.includes(task.id)
			) {
				draft.userView.userViews[userId]?.completedTasks.taskIds.push(
					task.id
				)
			}
		})

		setTimeout(() => {
			rebuildTable(set)
		})
	}

export const createRemoveTaskFromTable =
	(set: MutatedSetState, get: GetState<AppState>) => (taskId: string) => {
		set((draft) => {
			const playerId = draft.player.id
			const userId = draft.userView.user.id
			if (!userId || !playerId) {
				return
			}

			const index =
				draft.userView.userViews[userId]?.activeTasks.taskIds.indexOf(
					taskId
				)
			if (index != null && index > -1) {
				draft.userView.userViews[userId]?.activeTasks.taskIds.splice(
					index,
					1
				)
			}
		})

		setTimeout(() => {
			rebuildTable(set)
		})
	}

export const createRemoveCompletedTask =
	(set: MutatedSetState, get: GetState<AppState>) => (taskId: string) => {
		set((draft) => {
			const playerId = draft.player.id
			const userId = draft.userView.user.id
			if (!userId || !playerId) {
				return
			}

			const index =
				draft.userView.userViews[
					userId
				]?.completedTasks.taskIds.indexOf(taskId)
			if (index != null && index > -1) {
				draft.userView.userViews[userId]?.completedTasks.taskIds.splice(
					index,
					1
				)
			}
		})

		setTimeout(() => {
			rebuildTable(set)
		})
	}

export const createUpdateTaskInTable =
	(set: MutatedSetState, get: GetState<AppState>) =>
	(taskId: string, changes: Partial<Task>) => {
		const addTaskToTable = createAddTaskToTable(set, get)
		const removeTaskFromTable = createRemoveTaskFromTable(set, get)
		const addCompletedTask = createAddCompletedTask(set, get)
		const removeCompletedTask = createRemoveCompletedTask(set, get)
		const playerId = get().player.id
		const userId = get().userView.user.id
		if (!userId || !playerId) {
			return
		}

		const prevTask = get().queryClient.getQueryData<Task>(
			taskKeys.detail(taskId)
		)
		if (!prevTask) {
			return
		}
		const task = mergeTask(prevTask, changes)

		if ('assigneeId' in changes) {
			if (changes.assigneeId === userId) {
				addTaskToTable(task)
			} else {
				removeTaskFromTable(taskId)
			}
		}

		if ('statusCode' in changes) {
			if (isActive(changes.statusCode)) {
				addTaskToTable(task)
				removeCompletedTask(taskId)
			} else {
				removeTaskFromTable(taskId)
				addCompletedTask(task)
			}
		}
	}

export const rebuildTable = (set: MutatedSetState) => {
	set((draft) => {
		const playerId = draft.player.id
		const userId = draft.userView.user.id
		const queryClient = draft.queryClient as QueryClient

		if (!playerId || !userId) {
			return
		}

		const userView = draft.userView.userViews[userId]

		const activeTaskIds = userView?.activeTasks.taskIds || []
		const seletedFilters = userView?.filter.selected
		const direction = userView?.table.sort.direction
		const orderBy = userView?.table.sort.orderBy || ''

		const activeTasks = mapExistingTasks(queryClient, activeTaskIds)

		const criteria = new AndCriteria(seletedFilters)
		const filteredTasks = filter(criteria.check, activeTasks)
		const sortedTasks = sortTasks(filteredTasks, direction, orderBy)
		const taskIds = sortedTasks.map(prop('id'))

		if (
			userView != null &&
			!isDeepEqual(taskIds, userView?.table.taskIds)
		) {
			userView.table.taskIds = taskIds
		}
	})
}
