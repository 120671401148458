import memoizeOne from 'memoize-one'
import { equals } from 'ramda'

import { Cursor, Task } from '../types'
import { IdSelector, Selector, TaskByIdMap } from './store-types'

const selectLiveCursorsKeys = memoizeOne(
	(liveCursors) => Object.keys(liveCursors),
	([a], [b]) => equals(Object.keys(a), Object.keys(b))
)

// Projects
export const selectUserCursorIds: Selector<string[]> = (state) =>
	selectLiveCursorsKeys(state.projects.liveCursors)
export const selectUserCursor: IdSelector<Cursor> = (id) => (state) =>
	state.projects.liveCursors[id]
export const selectProjectsById: Selector<TaskByIdMap> = (state) =>
	state.projects.tasks.byId
export const selectProjectsSort: Selector<Task['id'][]> = (state) =>
	state.projects.tasks.sort
export const selectProject: IdSelector<Task> = (id) => (state) =>
	state.projects.tasks.byId[id]
