import { QueryClient, QueryObserverResult } from '@tanstack/react-query'
import { isBefore } from 'date-fns'
import { RawDraftContentState } from 'draft-js'
import { produce } from 'immer'
import { mergeDeepRight } from 'ramda'
import { unique } from 'remeda'
import { EMPTY, merge as rxjsMerge } from 'rxjs'
import {
	combineLatestWith,
	distinctUntilChanged,
	filter as rxjsFilter,
	shareReplay,
	startWith,
	switchMap,
	tap,
} from 'rxjs/operators'
import { Socket } from 'socket.io-client'

import { events } from '../../constants'
import { addUrlsToFile, convertApiFileToAttachment } from '../../helpers'
import { emailToHash } from '../../helpers/gravatar'
import { isInactive } from '../../helpers/taskStatus'
import { createSocketObservable } from '../../socketUtils'
import {
	parseTaskLogs,
	TaskActivity,
	TaskActivityComment,
	WrappedTaskActivityV2,
} from '../../task-activity'
import {
	createCommentActivity,
	createTaskActivityMove,
	createWrappedActivity,
} from '../../task-activity/create-activity'
import {
	createNewTaskActivity,
	createTaskActivity,
} from '../../task-activity/createTaskActivity'
import {
	AttachmentWithUrls,
	ReactionButton,
	Slice,
	Task,
	TaskActivityType,
	User,
} from '../../types'
import { ApiListResult, ApiTaskFile, ApiTaskListResult } from '../api'
import { fileKeys } from '../files/file-keys'
import { createAddTaskToQueryCache } from '../mutations'
import {
	addTaskSubject,
	createSubtaskObservable,
	moveTaskSubject,
	updateTaskSubject,
} from '../observables'
import { taskKeys, userKeys, workflowKeys } from '../queries'
import { MutatedAppState } from '../store-types'
import {
	createAddReactionMutation,
	createAddTaskActivityMutation,
	createAddTaskActivityToQueryCache,
	createRemoveReactionMutation,
} from '../task-activity'
import { addToTaskActivityLists } from '../task-activity/task-activity-utils'
import {
	FileRequestArgs,
	removeTaskFileMutation,
	UpdateFileArgs,
	updateTaskFileMutation,
} from '../taskFile/taskFileMutation'
import { createZustandObservable } from '../utils/createZustandObservable'
import { getServiceFromState } from '../utils/getServiceFromState'
import { mapExistingTasks } from '../utils/mapExistingTasks'
import { filterAndSortSubtasksToIds } from '../utils/subtasks'
import {
	addTaskReminderMutation,
	removeTaskReminderMutation,
} from './task-edit-mutations'

const createTaskActivitySocket = (socket: Socket) =>
	createSocketObservable<WrappedTaskActivityV2>(socket, events.TASK_ACTIVITY)

const createTaskFileUpdateSocket = (socket: Socket) =>
	createSocketObservable<{ id: string; sharedFiles: ApiTaskFile[] }>(
		socket,
		events.TASK_FILE_UPDATE
	)

export interface TaskEditSlice extends Slice {
	taskId: string | null
	activity: {
		filter: TaskActivityType | 'all'
		addActivity: (
			taskId: string,
			comment: TaskActivityComment['comment'],
			draftModel: RawDraftContentState,
			replyTo?: TaskActivityComment['replyTo']
		) => void
		addReaction: (reaction: ReactionButton, activity: TaskActivity) => void
		removeReaction: (
			reaction: ReactionButton,
			activity: TaskActivity
		) => void
		setFilter: (filter: TaskActivityType | 'all') => void
	}
	subtasks: {
		data: Task['id'][]
		isLoading: boolean
	}
	setTaskId: (taskId: string) => void
	// Subtasks
	addSubtask: (task: Partial<Task> & Pick<Task, 'id' | 'title'>) => void
	removeSubtask: (taskId: string) => void
	// Files
	updateFile: (args: UpdateFileArgs) => void
	removeFile: (args: FileRequestArgs) => void
	// Reminders
	addReminder: (taskId: string, date: Date) => void
	removeReminder: (taskId: string) => void
}

const addSubtaskToQueryCache = (
	queryClient: QueryClient,
	parentId: string,
	taskId: string
) => {
	queryClient.setQueryData<ApiTaskListResult | undefined>(
		taskKeys.list({ parentId }),
		produce((draft) => {
			if (!draft || draft.items.includes(taskId)) {
				return
			}
			draft.items.push(taskId)
			draft.count = draft.items.length
		})
	)
}

const removeSubtaskFromQueryCache = (
	queryClient: QueryClient,
	parentId: string | null,
	taskId: string
) => {
	if (!parentId) {
		return
	}
	queryClient.setQueryData(
		taskKeys.list({ parentId }),
		produce((draft) => {
			if (!draft) {
				return
			}
			const index = draft.items.indexOf(taskId)
			if (index > -1) {
				draft.items.splice(index, 1)
				draft.count = draft.items.length
			}
		})
	)
}

export const createTaskEditSlice: MutatedAppState<TaskEditSlice> = (
	set,
	get,
	api
) => ({
	taskId: null,
	activity: {
		filter: 'all',
		addActivity: (taskId, comment, draftModel, replyId = null) => {
			if (!comment || !taskId) return

			const playerId = get().player.id
			if (!playerId) return

			const { apiAdapter, queryClient } = get()
			const addTaskActivityMutation = createAddTaskActivityMutation(
				apiAdapter,
				queryClient
			)

			const activity = createCommentActivity(
				taskId,
				playerId,
				comment,
				draftModel,
				replyId
			)

			// Add to local state
			addTaskActivityMutation(activity)
		},
		addReaction: (reaction, activity) => {
			const { apiAdapter, queryClient, player } = get()

			if (!player.id) {
				return
			}

			const addReactionMutation = createAddReactionMutation(
				apiAdapter,
				queryClient
			)

			addReactionMutation({ ...reaction, userId: player.id }, activity)
		},
		removeReaction: (reaction, activity) => {
			const { apiAdapter, queryClient, player } = get()

			if (!player.id) {
				return
			}

			const removeReactionMutation = createRemoveReactionMutation(
				apiAdapter,
				queryClient
			)

			removeReactionMutation({ ...reaction, userId: player.id }, activity)
		},
		setFilter: (filter) => {
			set((draft) => {
				draft.taskEdit.activity.filter = filter
			})
		},
	},
	subtasks: {
		data: [],
		isLoading: false,
	},
	init: () => {
		const socket$ = getServiceFromState(api, 'socket')
		const { apiAdapter, queryClient } = get()

		// Setup sockets and other side effects
		// const addTaskActivityToQueryCache = createAddTaskActivityToQueryCache(
		// 	apiAdapter,
		// 	queryClient
		// )
		const addTaskToQueryCache = createAddTaskToQueryCache(queryClient)

		// Actions triggered by a user interaction
		const updateTaskSubscription = updateTaskSubject
			.pipe(rxjsFilter(({ taskId }) => taskId === get().taskEdit.taskId))
			.subscribe(async ({ changes, oldTask }) => {
				if (!oldTask) {
					return
				}

				const { queryClient } = get()
				const playerId = get().player.id
				const extra = {}

				if (playerId) {
					extra.user = queryClient.getQueryData(
						userKeys.detail(playerId)
					)
				}

				// TODO: use a different mergeDeep function with better typing
				// so that we don't have to cast to Task
				const newTask = mergeDeepRight(oldTask, changes) as Task

				if (newTask.workflowData?.id) {
					const workflowsById = queryClient.getQueryData(
						workflowKeys.list()
					)
					extra.workflow = workflowsById[newTask.workflowData.id]
				}

				// Disabled because optimistic IDs conflict with the IDs generated on the API. This makes
				// replying to comments, with IDs that will never end up on the database, a hard problem
				// to solve. For now, we'll just let the API handle the IDs.
				// const activity = createTaskActivity(
				// 	get().player.id || '',
				// 	oldTask,
				// 	newTask,
				// 	extra
				// )
				// if (activity) {
				// 	addTaskActivityToQueryCache(activity)
				// }

				set((draft) => {
					const currentTaskId = draft.taskEdit.taskId

					// Handle remove task from subtasks
					if (
						currentTaskId === changes.parentId &&
						isInactive(changes.statusCode)
					) {
						const index = draft.taskEdit.subtasks.data.indexOf(
							changes?.id
						)
						if (index > -1) {
							draft.taskEdit.subtasks.data.splice(index, 1)
						}
					}
				})
			})

		moveTaskSubject.subscribe(({ destination, source, taskId }) => {
			set((draft) => {
				const currentTaskId = draft.taskEdit.taskId

				const task = queryClient.getQueryData<Task>(
					taskKeys.detail(taskId)
				)

				if (task && source.parentId) {
					// Handle add task to subtasks
					if (
						currentTaskId &&
						currentTaskId === destination.parentId
					) {
						if (destination.parentId !== source.parentId) {
							addSubtaskToQueryCache(
								queryClient,
								currentTaskId,
								task.id
							)
						}
					}
					// Handle remove task from subtasks
					else if (currentTaskId === source.parentId) {
						removeSubtaskFromQueryCache(
							queryClient,
							currentTaskId,
							task.id
						)
					}

					// Add task activity for move
					const playerId = get().player.id
					if (!playerId) {
						throw new Error('Expected playerId to be truthy')
					}
					const user = queryClient.getQueryData<User>(
						userKeys.detail(playerId)
					)
					if (!user) {
						throw new Error('Expected user to be truthy')
					}
					const srcParent = queryClient.getQueryData<Task>(
						taskKeys.detail(source.parentId)
					)
					const srcParents = srcParent
						? [
								...(srcParent.parents || []),
								{ id: srcParent.id, title: srcParent.title },
							]
						: []

					// Disabled because optimistic IDs conflict with the IDs generated on the API. This makes
					// replying to comments, with IDs that will never end up on the database, a hard problem
					// to solve. For now, we'll just let the API handle the IDs.
					// const activity = createTaskActivityMove(
					// 	{
					// 		...pick(user, ['id', 'name', 'nickname']),
					// 		gravatar: emailToHash(user.email),
					// 	},
					// 	{
					// 		id: task.id,
					// 		parentId: source.parentId || null,
					// 		parents: srcParents,
					// 	},
					// 	pick(task, ['id', 'parentId', 'parents'])
					// )
					// const wrappedActivity = createWrappedActivity(
					// 	playerId,
					// 	task.id,
					// 	parseTaskLogs(activity),
					// 	activity
					// )
					// addTaskActivityToQueryCache(wrappedActivity)
				}
			})
		})

		// Actions triggered by the API
		const taskActivitySocketSubscription = socket$
			.pipe(switchMap(createTaskActivitySocket))
			.subscribe((activity) => {
				addToTaskActivityLists(queryClient, activity)
			})

		const taskFileUpdateSocketSubscription = socket$
			.pipe(switchMap(createTaskFileUpdateSocket))
			.subscribe(({ id: taskId, sharedFiles: files }) => {
				const fileListQueryKey = taskKeys.filesList(taskId)

				const convertFile = (file: ApiTaskFile): AttachmentWithUrls =>
					addUrlsToFile(
						convertApiFileToAttachment(
							apiAdapter.apiInstance.defaults.baseURL || '',
							get().player.authToken || '',
							file
						)
					)

				queryClient.setQueryData<AttachmentWithUrls[]>(
					fileListQueryKey,
					files.map(convertFile)
				)

				for (const file of files) {
					const fileQueryKey = fileKeys.detail(file.id)
					queryClient.setQueryData<AttachmentWithUrls>(
						fileQueryKey,
						convertFile(file)
					)
				}
			})

		// Store changes
		const addTaskSubscription = addTaskSubject.subscribe(
			({ position, task }) => {
				const parentId = get().taskEdit.taskId
				if (parentId && task.parentId === parentId) {
					addTaskToQueryCache(task, position)

					const parent = queryClient.getQueryData<Task>(
						taskKeys.detail(parentId)
					)
					if (parent) {
						// Sort tasks based on childSortOrder
						const subtasks = mapExistingTasks(
							queryClient,
							get().taskEdit.subtasks.data
						)
						subtasks.push(task)
						const subtaskIds = filterAndSortSubtasksToIds(
							parent.childSortOrder,
							subtasks
						)
						set((draft) => {
							draft.taskEdit.subtasks.data = subtaskIds
						})
					} else {
						set((draft) => {
							draft.taskEdit.subtasks.data.push(task.id)
						})
					}
					// Run at end of event loop, so that the query cache has
					// time to update before the update subtasks runs
					setTimeout(() => {
						addSubtaskToQueryCache(queryClient, parentId, task.id)
					})
				}

				// Disabled because optimistic IDs conflict with the IDs generated on the API. This makes
				// replying to comments, with IDs that will never end up on the database, a hard problem
				// to solve. For now, we'll just let the API handle the IDs.
				// if (player.id) {
				// 	const activity = createNewTaskActivity(
				// 		'web',
				// 		player.id,
				// 		task.id
				// 	)
				// 	addTaskActivityToQueryCache(activity)
				// }
			}
		)

		const taskId$ = createZustandObservable(
			api,
			(state) => state.taskEdit.taskId,
			{ fireImmediately: true }
		).pipe(distinctUntilChanged(), shareReplay(1))
		const activityFilter$ = createZustandObservable(
			api,
			(state) => state.taskEdit.activity.filter
		).pipe(
			startWith({ state: 'all' as TaskActivityType | 'all' }),
			distinctUntilChanged(),
			shareReplay(1)
		)

		const updateSubtasks = (
			results:
				| [
						QueryObserverResult<Task>,
						QueryObserverResult<ApiListResult<string>>,
				  ]
				| null
		) => {
			const taskResults = results?.[0]
			const subtasksResults = results?.[1]
			if (!taskResults?.data || !subtasksResults?.data?.items) {
				set((draft) => {
					draft.taskEdit.subtasks.data = []
					draft.taskEdit.subtasks.isLoading = false
				})
				return
			}

			const childSortOrder = taskResults.data?.childSortOrder || []
			const subtasks = mapExistingTasks(
				queryClient,
				subtasksResults.data.items
			)
			const subtaskIds = filterAndSortSubtasksToIds(
				childSortOrder,
				subtasks
			)

			set((draft) => {
				draft.taskEdit.subtasks.data = unique(subtaskIds)
				draft.taskEdit.subtasks.isLoading = false
			})
		}

		// Listen for taskId change
		// When change happens, teardown subscriptions and create new ones:
		// - subscribe to activity changes
		// - subscribe to subtask changes
		// - subscribe to file changes
		const taskIdSubscription = taskId$
			.pipe(
				combineLatestWith(activityFilter$),
				tap(() => {
					set((draft) => {
						draft.taskEdit.subtasks.isLoading = true
					})
				}),
				switchMap(([{ state: taskId }]) => {
					if (!taskId) {
						// Clear out state when taskId is set to null
						updateSubtasks(null)
						return EMPTY
					}
					return rxjsMerge(
						createSubtaskObservable(
							apiAdapter,
							queryClient,
							taskId
						).pipe(tap(updateSubtasks))
						// TODO: fetch files
					)
				})
			)
			.subscribe()

		return () => {
			taskActivitySocketSubscription.unsubscribe()
			taskFileUpdateSocketSubscription.unsubscribe()

			addTaskSubscription.unsubscribe()
			taskIdSubscription.unsubscribe()
			updateTaskSubscription.unsubscribe()
		}
	},
	setTaskId: (taskId: string) => {
		set((draft) => {
			draft.taskEdit.taskId = taskId
		})
	},
	addSubtask: (task) => {
		console.log('addSubtask', task)
	},
	removeSubtask: (taskId) => {
		console.log('removeSubtask', taskId)
	},
	addReminder: (taskId, date) => {
		if (isBefore(date, new Date())) {
			get().notifications.next({
				type: 'snackbar',
				message: 'Reminder date must be in the future',
			})
			return
		}
		addTaskReminderMutation(get(), taskId, date)
	},
	removeReminder: (taskId) => {
		removeTaskReminderMutation(get(), taskId)
	},
	updateFile: (args) => {
		const { apiAdapter, queryClient, player } = get()

		if (!player.id) {
			return
		}

		const update = updateTaskFileMutation(apiAdapter, queryClient)

		update(args)
	},
	removeFile: (args) => {
		const { apiAdapter, queryClient, player } = get()

		if (!player.id) {
			return
		}

		const remove = removeTaskFileMutation(apiAdapter, queryClient)
		remove(args)
	},
})
