import { prop, uniqueBy } from 'remeda'

import Filter from '../../filters/Filter'
import { StateMutationFn } from './user-view-types'

export const addFilterMutation: (filter: Filter) => StateMutationFn =
	(filter) => (draft) => {
		const currentUserId = draft.userView.user.id
		const currentFilters =
			draft.userView.userViews[currentUserId]?.filter.selected || []
		const filters = uniqueBy([filter, ...currentFilters], prop('type'))
		draft.userView.userViews[currentUserId].filter.selected = filters
	}

export const removeFilterMutation: (filterType: string) => StateMutationFn =
	(filterType) => (draft) => {
		const currentUserId = draft.userView.user.id
		const currentFilters =
			draft.userView.userViews[currentUserId]?.filter.selected || []
		const filters = currentFilters.filter(
			(f: Filter) => f.type !== filterType
		)
		draft.userView.userViews[currentUserId].filter.selected = filters
	}

export const resetFiltersMutation: StateMutationFn = (draft) => {
	const currentUserId = draft.userView.user.id
	draft.userView.userViews[currentUserId].filter.folder = []
	draft.userView.userViews[currentUserId].filter.owner = []
	draft.userView.userViews[currentUserId].filter.priority = []
	draft.userView.userViews[currentUserId].filter.search = []
	draft.userView.userViews[currentUserId].filter.selected = []
	draft.userView.userViews[currentUserId].filter.tags = []
	draft.userView.userViews[currentUserId].filter.workflow = []
}
