import { getUnixTime, parseISO } from 'date-fns'
import { pipe, prop, sortBy } from 'remeda'

import { SectionSortType, SidebarUserData, Task } from '../types'

export type SortableTask = unknown &
	Pick<
		Task,
		| 'dateCreated'
		| 'dueDate'
		| 'hoursAllocated'
		| 'importance'
		| 'isPinned'
		| 'ownerId'
		| 'parents'
		| 'startDate'
		| 'title'
		| 'urgency'
	>

type Comparator = 'asc' | 'desc'
type TaskSortProjection = (task: SortableTask) => boolean | number | string
export type TaskSortOrderRule =
	| TaskSortProjection
	| [TaskSortProjection, Comparator]

const URGENCY_WEIGHT = 1.5
const IMPORTANCE_WEIGHT = 1

export const sortByDate = (
	task: Partial<Task>,
	prop:
		| 'dateCreated'
		| 'dueDate'
		| 'dueTime'
		| 'startDate'
		| 'startTime' = 'startDate'
) => {
	const date =
		task && task[prop] && typeof task[prop] === 'string' ? task[prop] : null
	if (date == null) {
		return 0
	}
	const result = getUnixTime(parseISO(date))
	return isNaN(result) ? 0 : result
}

const hasUnreadMessages = (user: SidebarUserData) => user.unreadMessageCount > 0
const isBusy = (user: SidebarUserData) => Boolean(user.currentTaskId)
//const isOnline = (user: SidebarUserData) => user.isOnline
const isStarred = (user: SidebarUserData) => Boolean(user.isStarred)

export const sortSidebarUsers = (users: SidebarUserData[]) =>
	pipe(
		users,
		sortBy(
			[hasUnreadMessages, 'desc'],
			[isStarred, 'desc'],
			[isBusy, 'desc'],
			// Disable sorting online users
			//[isOnline, 'desc'],
			[prop('nickname'), 'asc']
		)
	)

export const sortFnsForOwner: TaskSortOrderRule[] = [
	(task) => task.ownerId,
	(task) => task.title,
]
export const sortFnsForTitle: TaskSortOrderRule[] = [
	(task) => task.title,
	(task) => sortByDate(task, 'startDate'),
]
export const sortFnsForProject: TaskSortOrderRule[] = [
	(task) => {
		const breadcrumb = task.parents.map((task) => task.title).join(' / ')
		return breadcrumb
	},
	(task) => task.title,
]
export const sortFnsForDuration: TaskSortOrderRule[] = [
	(task) => task.hoursAllocated,
]
export const sortFnsForStartDate: TaskSortOrderRule[] = [
	(task) => sortByDate(task, 'startDate'),
	(task) => task.title,
]
export const sortFnsForDueDate: TaskSortOrderRule[] = [
	(task) => sortByDate(task, 'dueDate'),
	(task) => task.title,
]
export const sortFnsForPriority: TaskSortOrderRule[] = [
	(task) => {
		const { importance = 0, urgency = 0 } = task
		return 0 - (urgency * URGENCY_WEIGHT + importance * IMPORTANCE_WEIGHT)
	},
	(task) => sortByDate(task, 'startDate'),
	(task) => sortByDate(task, 'startTime'),
	[(task) => sortByDate(task, 'dateCreated'), 'desc'],
	(task) => task.title,
]

export const sortTasks = <T extends SortableTask>(
	tasks: T[],
	type: SectionSortType
): T[] => {
	switch (type) {
		case 'newest':
			return sortBy(
				tasks,
				[(task) => sortByDate(task), 'desc'],
				[(task) => sortByDate(task, 'dateCreated'), 'desc'],
				[prop('title'), 'asc']
			)
		case 'oldest':
			return sortBy(
				tasks,
				[(task) => sortByDate(task), 'asc'],
				[(task) => sortByDate(task, 'dateCreated'), 'asc'],
				[prop('title'), 'asc']
			)
		case 'priority':
			return sortBy(
				tasks,
				(task) => {
					const { importance = 0, urgency = 0 } = task
					return (
						0 -
						(urgency * URGENCY_WEIGHT +
							importance * IMPORTANCE_WEIGHT)
					)
				},
				(task) => sortByDate(task, 'startDate'),
				(task) => sortByDate(task, 'startTime'),
				[(task) => sortByDate(task, 'dateCreated'), 'desc'],
				(task) => task.title
			)

		case 'pinned':
			return sortBy(tasks, [prop('isPinned'), 'desc'])

		case 'title':
			return sortBy(
				tasks,
				(task) => task.title,
				(task) => sortByDate(task, 'startDate')
			)
		default:
			return tasks
	}
}

export const sortWithOrder = <T = { id: string }>(
	entities: (T & { id: string })[],
	sortOrder: string[]
): T[] => {
	if (!sortOrder) {
		return entities
	}

	try {
		return sortBy(entities, (entity) => {
			let index = sortOrder.indexOf(entity?.id)

			// Put tasks that don't have sort information at the bottom
			if (index === -1) {
				index = sortOrder.length
			}

			return index
		})
	} catch (err) {
		return entities
	}
}
