import { createEmptySectionIndex } from '../../helpers/sections'
import { StateMutationFn } from './user-view-types'
import { tableSortDefaults } from './user-view-utils'

const resetActiveTasks: StateMutationFn = (draft) => {
	draft.userView.activeTasks.isLoading = false
	draft.userView.activeTasks.privateTaskCount = 0
	draft.userView.activeTasks.taskIds = []
}

const resetCompletedTasks: StateMutationFn = (draft) => {
	draft.userView.completedTasks.isLoading = false
	draft.userView.completedTasks.privateTaskCount = 0
	draft.userView.completedTasks.taskIds = []
}

const resetFollowUp: StateMutationFn = (draft) => {
	draft.userView.followUp.taskIds = []
	draft.userView.followUp.isLoading = false
}

const resetSections: StateMutationFn = (draft) => {
	draft.userView.sections.byId = createEmptySectionIndex()
	draft.userView.sections.displayOrder = []
	draft.userView.sections.isFetching = false
	draft.userView.sections.isLoading = false
	draft.userView.sections.privateTaskCount = 0
	draft.userView.sections.taskCount = 0
}

const resetTable: StateMutationFn = (draft) => {
	draft.userView.table.taskIds = []
	draft.userView.table.sort = tableSortDefaults
}

export const resetUserView: StateMutationFn = (draft) => {
	resetActiveTasks(draft)
	resetCompletedTasks(draft)
	resetSections(draft)
	resetTable(draft)
	resetFollowUp(draft)
}

export const changeUser =
	(userId: string): StateMutationFn =>
	(draft) => {
		draft.userView.user.id = userId
	}
